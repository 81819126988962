

























































import Vue from 'vue';
import { mapGetters } from 'vuex';

import CheckBox from '@/components/CheckBox.vue';
import client from '@/store/client';
import Language from '@/constants';

interface CData {
  file: File | null;
  progress: string;
  downloadLink: string;
  lang: Language;
  Language: typeof Language;
}

interface CMethods {
  addFile: (files: File[]) => void;
  convertPDF: () => void;
}

interface CComputed {
  pValue: string;
  isLoggedin: boolean;
}

export default Vue.extend<CData, CMethods, CComputed>({
  name: 'demo',
  data: () => ({
    file: null,
    progress: '',
    downloadLink: '',
    lang: Language.Eng,
    Language,
  }),
  methods: {
    async convertPDF() {
      if (this.file === null) return;
      try {
        const data = new FormData();

        data.append('file', this.file);

        this.progress = 'Converting...';
        this.downloadLink = '';

        const headers: { [key: string]: any } = {};

        const jwt = await client.authentication.getAccessToken();

        if (jwt) {
          headers.Authorization = `Bearer ${jwt}`;
        }

        const response = await fetch(`/braille?lang=${this.lang}`, {
          method: 'POST',
          body: data,
          headers,
        });

        const resJSON = await response.json();

        if (!response.ok) {
          throw resJSON;
        }

        this.progress = '';
        this.downloadLink = resJSON.route;
      } catch (e) {
        console.error(e);
        this.progress = '';
        this.$notify({
          title: 'Failed to convert',
          text: e.message,
          type: 'error',
        });
      }
    },
    addFile([file]) {
      this.file = file ?? null;
    },
  },
  computed: {
    ...mapGetters(['isLoggedin']),
    pValue() {
      if (this.file) {
        return `Uploaded File: ${this.file.name}`;
      }
      return '';
    },
  },
  components: {
    CheckBox,
  },
});
